import { ReactElement, useCallback, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import gigantesLogo from '../../assets/img/incentivo-2024/home/LOGO_GIGANTES.png'
import ameba from '../../assets/img/incentivo-2024/home/amebas.png'
import logoMdlz from '../../assets/img/incentivo-2024/logo_MDLZ.png'
import MenuChatIcon from '../../assets/img/mobile/home/menu-icons/chat-outline.svg'
import CloseIcon from '../../assets/img/mobile/home/menu-icons/close.svg'
import ClubeMdlzIcon from '../../assets/img/mobile/home/menu-icons/clube-mdlz.svg'
import MenuGearIcon from '../../assets/img/mobile/home/menu-icons/gear-outline.svg'
import NoticiasIcon from '../../assets/img/mobile/home/menu-icons/noticias.png'
import QuestionMarkIcon from '../../assets/img/mobile/home/menu-icons/question-mark.svg'
import MenuRankingIcon from '../../assets/img/mobile/home/menu-icons/ranking-outline.svg'
import ShoppingBagIcon from '../../assets/img/mobile/home/menu-icons/shop-bag.png'
import StarIcon from '../../assets/img/mobile/home/menu-icons/star.svg'
import TrophyIcon from '../../assets/img/mobile/home/menu-icons/trophy.svg'
import toggle from '../../assets/img/mobile/home/menu_toggle.png'
import { useAuth } from '../../contexts/AuthContext'
import { useModalMdlz } from '../../contexts/ModalMdlzContext'
import { useClickOutside } from '../../hooks/useClickOutside'
import api from '../../services/api'
import NoticeGroup from '../Header/NoticeGroup'
import navList from './navList'
import * as S from './styled'

export default function HeaderComponent (): ReactElement {
  const [displayMenu, setDisplayMenu] = useState(false)
  const [showChildren, setShowChildren] = useState<boolean[]>([])
  const { avatar, doLogout, user, userCan } = useAuth()
  const [visible, setVisible] = useState(false)
  const [wrapperRef] = useClickOutside(() => setVisible(false))
  const [subMenuRef] = useClickOutside(() => setShowChildren(showChildren.map(() => false)))
  const { openModal } = useModalMdlz()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const openSubMenu = useCallback((evt: any, index: number) => {
    evt.preventDefault()
    const allClosed = showChildren.map(() => false)
    allClosed[index] = true
    setShowChildren(allClosed)
  }, [showChildren])

  const handleScroll = useCallback((id: string) => {
    navigate(`/home${id}`)
    setDisplayMenu(false)
  }, [])

  const handleCertificateDownload = useCallback(async (onda: number): Promise<void> => {
    try {
      const response = await api.get('/certificate', { params: { onda }, responseType: 'arraybuffer' })
      const blob = new Blob([response.data])
      const anchor = document.createElement('a')
      const url = URL.createObjectURL(blob)
      anchor.href = url
      anchor.download = `certificado-onda${onda}.pdf`
      document.body.appendChild(anchor)
      anchor.click()
      setTimeout(function () {
        document.body.removeChild(anchor)
        window.URL.revokeObjectURL(url)
      }, 0)
    } catch (err) {
      openModal({
        body: <S.ModalText>Você não tem certificado para baixar!</S.ModalText>,
        customStyles: {
          backgroundColor: 'var(--white)',
          padding: '48px 32px',
          width: 'fit-content'
        }
      })
    }
  }, [])

  return <>
    <S.Header menuOpen={displayMenu}>
      <S.HeaderImg src={ameba} />

      <S.HeaderContent>
        <S.ToggleContainer>
          <S.MenuToggle onClick={() => setDisplayMenu(!displayMenu)}>
            <img src={toggle} />
          </S.MenuToggle>
        </S.ToggleContainer>

        {
          displayMenu && <S.Menu>
            <S.CloseIcon onClick={() => setDisplayMenu(false)}>
              <img src={CloseIcon} alt="Fechar" />
            </S.CloseIcon>

            <div>

              <S.MenuItem onClick={async () => handleScroll('#premios')}>
                <img src={TrophyIcon} />
                <S.MLink>Prêmios</S.MLink>
              </S.MenuItem>

              {userCan('read:mecanicas-2') && <S.MenuItem onClick={async () => handleScroll('#mecanicas')}>
                <img src={MenuGearIcon} />
                <S.MLink>Mecânicas</S.MLink>
              </S.MenuItem>}

              <S.MenuItemLink to="/mecanicas/pascoa">
                <img src={MenuGearIcon} />
                <p>Páscoa</p>
              </S.MenuItemLink>

              {userCan('read:hub-campanhas') && <S.MenuItemLink to="/hub-de-campanhas">
                <img src={MenuGearIcon} />
                <p>HUB de Campanhas</p>
              </S.MenuItemLink>}

              <S.MenuItem onClick={async () => handleScroll('#ativacoes')}>
                <img src={ClubeMdlzIcon} />
                <S.MLink>Ativações</S.MLink>
              </S.MenuItem>

              <S.MenuItemLink to="/jogos/memoria">
                <img src={ClubeMdlzIcon} />
                <S.MLink>Memória de Gigantes</S.MLink>
              </S.MenuItemLink>

              {userCan('play:gira-giro') && <S.MenuItemLink to="/jogos/gira-giro">
                <img src={ClubeMdlzIcon} />
                <S.MLink>Gira-Giro</S.MLink>
              </S.MenuItemLink>}

              <S.MenuItem onClick={async () => handleScroll('#ranking')}>
                <img src={MenuRankingIcon} />
                <S.MLink>Ranking</S.MLink>
              </S.MenuItem>

              {userCan('read:winners') && <S.MenuItem onClick={async () => handleScroll('#ganhadores')}>
                <img src={StarIcon} />
                <S.MLink>Ganhadores</S.MLink>
              </S.MenuItem>}

              <S.MenuItem onClick={async () => handleScroll('#noticias')}>
                <img src={NoticiasIcon} />
                <S.MLink>Notícias</S.MLink>
              </S.MenuItem>

              <S.MenuItem>
                <img src={QuestionMarkIcon} />
                <Link to="/faq">Dúvidas</Link>
              </S.MenuItem>

              <S.MenuItem>
                <img src={MenuChatIcon} />
                <Link to="/fale-conosco">Fale Conosco</Link>
              </S.MenuItem>

              {userCan('read:marketplace') && <S.MenuItem>
                <img src={ShoppingBagIcon} />
                <Link to="/marketplace">Shopping de prêmios</Link>
              </S.MenuItem>}

              {userCan('read:cart') && <S.MenuItem>
                <img src={ShoppingBagIcon} />
                <Link to="/cart">Carrinho</Link>
              </S.MenuItem>}

              {(userCan('read:loja-perfeita') && pathname.includes('home')) && <S.MenuItem>
                <img src={StarIcon} />
                <a
                  href="https://wd3.myworkday.com/mdlz/learning/course/3f40181c6f211000960fa73f83270000?type=9882927d138b100019b928e75843018d"
                  target="_blank"
                  rel="noreferrer"
                >
                  Loja Perfeita
                </a>
              </S.MenuItem>}
            </div>
          </S.Menu>
        }

        <Link to="/home">
          <S.MdlzLogoContainer>
            <img alt="Mondelēz International" src={logoMdlz} />
          </S.MdlzLogoContainer>
        </Link>

        <S.NavList>
          {navList.map(({ pathTo, title, children, permission }, index) => {
            if (permission && !userCan(permission)) return <></>
            return <S.NavListItem>
              {pathTo
                ? <Link to={pathTo}>{title}</Link>
                : <a href="#" onClick={(evt) => openSubMenu(evt, index)}>{title}</a>
              }
              <S.Children ref={subMenuRef} className={showChildren[index] ? 'menu-open' : 'menu-close'}>
                {children?.map((child: any) => {
                  if (child.permission && !userCan(child.permission)) return <></>
                  return <>
                    <S.ChildrenItem to={child.path}>{child.title}</S.ChildrenItem>
                  </>
                })}
              </S.Children>
            </S.NavListItem>
          }
          )}
          {userCan('read:cart') && <div className='action-list'>
            <Link to='/cart'>
              <svg className="icon-shopping-bag">
                <use href="#svg-shopping-bag"></use>
              </svg>
            </Link>
          </div>}

          {(userCan('read:loja-perfeita') && pathname.includes('home')) && <S.NavListItem>
            <a
              href="https://wd3.myworkday.com/mdlz/learning/course/3f40181c6f211000960fa73f83270000?type=9882927d138b100019b928e75843018d"
              target="_blank"
              rel="noreferrer"
            >
              Loja Perfeita
            </a>
          </S.NavListItem>}
        </S.NavList>

        <S.DropdownContainer onClick={() => { setVisible(!visible) }}>
          <S.Avatar>
            <img src={avatar} />
          </S.Avatar>

          <S.DropdownToggleContainer ref={wrapperRef}>
            <p className='display-name'>{user.displayName}</p>

            <svg>
              <use href="#svg-small-arrow"></use>
            </svg>

            <S.Dropbox active={visible} className="dropdown-navigation header-settings-dropdown">

              {userCan('user-status:export') && <Link className="dropdown-navigation-link" to="/admin">Admin</Link>}
              <Link className="dropdown-navigation-link" to="/perfil">Meu perfil</Link>
              <Link className="dropdown-navigation-link" to="/regulamento">Regulamento</Link>
              <Link className="dropdown-navigation-link" to="/fale-conosco">Fale conosco</Link>
              <Link className="dropdown-navigation-link" to="/terms">Termos de Uso</Link>
              <Link className="dropdown-navigation-link" to="" download onClick={async () => await handleCertificateDownload(1)}>Certificado ONDA 1</Link>
              {userCan('download:step-2') && <Link className="dropdown-navigation-link" to="" download onClick={async () => await handleCertificateDownload(2)}>Certificado ONDA 2</Link>}
              {userCan('download:step-3') && <Link className="dropdown-navigation-link" to="" download onClick={async () => await handleCertificateDownload(3)}>Certificado ONDA 3</Link>}
              {userCan('download:step-4') && <Link className="dropdown-navigation-link" to="" download onClick={async () => await handleCertificateDownload(4)}>Certificado ONDA 4</Link>}

              <S.LeaveButton
                onClick={doLogout}
                className="dropdown-navigation-button button small secondary"
                type="button"
              >
                <i className="fas fa-door-closed"></i>
                <span>Sair</span>
              </S.LeaveButton>
            </S.Dropbox>
          </S.DropdownToggleContainer>

        </S.DropdownContainer>

      </S.HeaderContent>

      <S.GigantesLogoLink to="/home">
        <S.GigantesLogo>
          <img alt="Gigantes da Execução 2024" src={gigantesLogo} />
        </S.GigantesLogo>
      </S.GigantesLogoLink>
    </S.Header>
    <NoticeGroup />
  </>
}
