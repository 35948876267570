import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import boy from '../../assets/img/memory-game/boy.png'
import coin from '../../assets/img/memory-game/coin.png'
import { useModalMdlz } from '../../contexts/ModalMdlzContext'
import api from '../../services/api'
import Card from './Card'
import CountdownComponent from './CountdownComponent'
import { CardState } from './Data/Entities/CardState'
import { CardStatus } from './Data/Entities/CardStatus'
import { GameState } from './Data/Entities/GameState'
import { GameStatus } from './Data/Entities/GameStatus'
import { StartNewGame } from './Doamin/UseCase/StartNewGame'
import Message from './Message'
import * as S from './style'

const startNewGame = new StartNewGame()

export function Memoria (): ReactElement {
  const { closeModal, openModal } = useModalMdlz()
  const [state, setState] = useState<GameStatus>()

  const setCardState = useCallback(async (cardState: CardState, cardIndex: number) => {
    if (!state) return
    if (!state.canFlip) return
    if (state.cardList[cardIndex].cardState === cardState) return
    const newState = { ...state }
    newState.cardList[cardIndex].cardState = cardState
    setState(newState)
    if (!newState.currentFlipped) {
      newState.currentFlipped = newState.cardList[cardIndex]
      return
    }
    setState({ ...state, canFlip: false })
    setTimeout(async () => {
      setState({ ...state, canFlip: true })
      if (!newState.currentFlipped) return
      if (newState.currentFlipped.id !== newState.cardList[cardIndex].id) {
        newState.currentFlipped.cardState = CardState.FLIP_DOWN
        newState.cardList[cardIndex].cardState = CardState.FLIP_DOWN
        newState.points -= 10

        if (newState.points === 0) {
          newState.gameState = GameState.LOSE
        }
      } else {
        newState.flipCards += 2

        if (newState.flipCards === newState.cardList.length) {
          try {
            await api.post('/games/memory', {
              points: newState.points,
              memoryGameId: 2
            })
            newState.gameState = GameState.WIN
            handleModalToggle(GameState.WIN)
          } catch (err: any) {
            toast.error(err.message, { theme: 'colored' })
          }
        }
      }
      newState.currentFlipped = undefined
      setState(newState)
    }, 1000)
  }, [state])

  const tryAgain = async (): Promise<void> => {
    closeModal()
    await handleNewGame()
  }

  const gameEndingModal = {
    1: <S.ModalContainer>
      <h1>Você<br />ganhou!</h1>

      <S.DigimoedasWonContainer>
        <p>{state?.points}</p>

        <figure>
          <img src={coin} alt="Digimoeda" />
        </figure>
      </S.DigimoedasWonContainer>

      <p>digimoedas</p>

      <S.CongratulationsButton onClick={closeModal}>
        Parabéns!
      </S.CongratulationsButton>
    </S.ModalContainer>,
    2: <S.ModalContainer>
      <h1>Game<br />Over</h1>

      <S.GiganteFigure>
        <img src={boy} alt="Garoto jogando" />
      </S.GiganteFigure>

      <S.TryAgainButton onClick={tryAgain}>
        Tentar novamente
      </S.TryAgainButton>
    </S.ModalContainer>
  }

  const handleModalToggle = useCallback(async (gameState: GameState.LOSE | GameState.WIN) => {
    openModal({
      body: gameEndingModal[gameState],
      goTo: '/jogos/memoria'
    })
  }, [closeModal, openModal, state])

  const handleNewGame = async (): Promise<void> => {
    const state = await startNewGame.handle()
    setState(state)
  }

  useEffect(() => {
    handleNewGame()
  }, [])

  useEffect(() => {
    if (!state) return
    const interval = setInterval(() => {
      if (state.gameState !== GameState.PLAY) return
      const newState = { ...state }
      newState.time -= 1

      if ((180 - newState.time) % 10 === 0) {
        newState.points -= 10
      }

      if (newState.time === 0) {
        newState.gameState = GameState.LOSE
        handleModalToggle(GameState.LOSE)
      }
      setState(newState)
    }, 1000)
    return () => clearInterval(interval)
  }, [state])

  const points = useMemo(() => {
    if (!state) return 0
    if (!state.MemoryGameWin) return state.points
    if (state.MemoryGameWin.length === 0) return state.points
    return state.MemoryGameWin[0].points
  }, [state])

  if (!state) return <>...</>

  return <><S.Container className={state.gameState === GameState.WIN ? 'win' : ''}>
    <div>
      <h1>Memória de Gigantes</h1>

      {state.gameState === GameState.PLAY && <S.CardsContainer>
        {state.cardList.map((card: CardStatus, index: number): ReactElement =>
          <Card
            {...card}
            key={index}
            onClick={async () => await setCardState(CardState.FLIP_UP, index)}
          >
            {card.text
              ? card.text
              : <img src={card.image?.path} />}
          </Card>
        )}
      </S.CardsContainer>}
    </div>

    <div>
      {state.gameState === GameState.PLAY && <CountdownComponent time={state.time} />}

      {state.gameState === GameState.PLAY && <S.DigimoedasContainer>
        <p>{state.points} Digimoedas</p>

        <figure>
          <img src={coin} alt="Digimoeda" />
        </figure>
      </S.DigimoedasContainer>}
    </div>

    {state.gameState === GameState.WIN && <div>
      <S.WinMessage>Você Já conquistou digimoedas neste jogo. Veja abaixo:</S.WinMessage>
      <S.DigimoedasContainer>
        <p>{points} Digimoedas</p>

        <figure>
          <img src={coin} alt="Digimoeda" />
        </figure>
      </S.DigimoedasContainer>
    </div>}

  </S.Container>
    <Message /></>
}
