import styled from 'styled-components'

export const CardContainer = styled.div`
  position: relative;
`

export const ImageContainer = styled.figure`
  margin: 0;
  max-width: 500px;
`

export const CharacterContainer = styled.figure`
  bottom: -108px;
  left: 0;
  position: absolute;
  max-width: 180px;

  @media (max-width: 520px) {
    bottom: -84px;
    left: -28px;
    max-width: 140px;
  }
`

export const Content = styled.div`
  margin: 16px 0 20px;
  z-index: 0;

  h1 {
    font-size: 60px;
    color: var(--white);
    line-height: 40px;
    margin: 16px 0 0;
    text-align: center;
    text-transform: uppercase;

    span {
      :first-of-type {
        font-size: 32px;
      }

      &:last-child {
        color: #f5be4a;
      }
    }
  }
`

export const StartButton = styled.button`
  background-color: #f5be4a;
  border-radius: 2px;
  color: #2d367e;
  font-size: 32px;
  font-weight: 500;
  margin-top: 24px;
  padding-inline: 32px;
  width: fit-content;
`

export const Paragraph = styled.p`
  margin-bottom: 8px;
  text-align: left !important;
`

export const ModalContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;

  ul {
    list-style: disc inside;
  }

  h1, p {
    text-align: center;
  }

  h1 {
    border-bottom: 6px solid #ffdc39;
    font-family: MdlzBiteType;
    font-size: 48px;
    letter-spacing: -2px;
    color: #ffdc39;
    text-align: center;
    white-space: nowrap;

    @media (max-width: 520px) {
      font-size: 40px;
    }
  }

  p, li {
    color: var(--white);
    font-family: Roboto;
    font-size: 16px;
  }
`

export const ModalButton = styled.button`
  background-color: #ffdc39;
  border-radius: 2px;
  color: #0e3e8a;
  font-family: MdlzBiteType;
  font-size: 28px;
  font-weight: 400;
  max-width: fit-content;
  padding: 4px 32px;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 520px) {
    font-size: 24px;
  }
`
