import { ReactElement, useCallback } from 'react'
import boy from '../../../assets/img/memory-game/boy.png'
import { useModalMdlz } from '../../../contexts/ModalMdlzContext'
import Message from '../Message'
import banner from './assets/banner-Jogo.png'
import StartGameProps from './props'
import * as S from './style'

export default function StartGame ({ handleStart }: StartGameProps): ReactElement {
  const { closeModal, openModal } = useModalMdlz()

  const handleModalToggle = useCallback(async () => {
    openModal({
      size: 'medium',
      body: <S.ModalContainer>
        <h1>Regras Gerais:</h1>

        <ul>
          <li>As cartas vão apresentar uma frase referente ao Oreo Coca-cola e a imagem do respectivo produto;</li>
          <li>Assim que as cartas estiverem viradas com o logo de Gigantes, é só clicar em uma por vez para formar o par certinho;</li>
          <li>Você começa com 1.000 pontos e vai perdendo conforme erra ou demora para finalizar o jogo;</li>
          <li>O tempo limite de jogo é de 3 minutos;</li>
          <li>Não há limite de tentativas! Enquanto não acertar todos os pares dentro do limite de tempo, você pode jogar.</li>
          <li>Ao final, o número de pontos que você encerra o jogo é o total de digimoedas que você ganhou!</li>
        </ul>

        <S.ModalButton onClick={startGame}>
          Jogar
        </S.ModalButton>
      </S.ModalContainer>
    })
  }, [closeModal, openModal])

  const startGame = (): void => {
    closeModal()
    handleStart(true)
  }

  return <>
    <S.CardContainer>
      <S.ImageContainer>
        <img src={banner} />
      </S.ImageContainer>

      <S.CharacterContainer>
        <img src={boy} alt="Garoto jogando" />
      </S.CharacterContainer>
    </S.CardContainer>

    <S.Content>
      <h1>
        Memória
        <br />
        <span>de</span>
        <br />
        <span>Gigantes</span>
      </h1>
    </S.Content>

    <S.StartButton onClick={handleModalToggle}>
      Iniciar
    </S.StartButton>

    <Message />
  </>
}
