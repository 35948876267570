import { ReactElement, useLayoutEffect } from 'react'
import alexaDot from './assets/alexa-dot.png'
import alexaShow from './assets/alexa-show.png'
import ameba1 from './assets/amebas-bg1.png'
import ameba2 from './assets/amebas-bg2.png'
import bag from './assets/bag.png'
import bag2 from './assets/bag2.png'
import bannerIlhaBis from './assets/banner_conquistesuailhabis_resultado_final.png'
import jbl from './assets/jbl.png'
import winner1 from './assets/winner1.png'
import winner2 from './assets/winner2.png'
import winner3 from './assets/winner3.png'
import winner4 from './assets/winner4.png'
import winner5 from './assets/winner5.png'
import winner6 from './assets/winner6.png'
import winner7 from './assets/winner7.png'
import winner8 from './assets/winner8.png'
import RecognitionWaveOne from './RecognitionWaveOne'
import RecognitionWaveTwo from './RecognitionWaveTwo'
import * as S from './style'
import WaveTwoWinners from './WaveTwoWinners'
import WinnerCard from './WinnerCard'

export default function Winners (): ReactElement {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <S.Template>
    <S.Container>
      <RecognitionWaveTwo />

      <WaveTwoWinners />

      <S.BisWinners>
        <img src={bannerIlhaBis} alt="" />
      </S.BisWinners>

      <RecognitionWaveOne />

      <S.BannerContainer>
          <figure>
            <img src={ameba1} alt="" />
          </figure>

          <figure>
            <img src={ameba2} alt="" />
          </figure>

        <S.BannerBg>
          <h1>Ganhadores</h1>

          <S.WinnersContainer>
            <WinnerCard
              avatarImg={winner1}
              digimoedas="3990"
              prizeImg={alexaDot}
              prizeName="Alexa Eco Dot"
              winnerName="Marcos Eduardo Gonçalves de Souza"
            />

            <WinnerCard
              avatarImg={winner2}
              digimoedas="3980"
              prizeImg={jbl}
              prizeName="JBL Mini"
              winnerName="Renata dos Anjos Paulo"
            />

            <WinnerCard
              avatarImg={winner3}
              digimoedas="3960"
              prizeImg={bag}
              prizeName="Mochila 1 Fjallraven"
              winnerName="Werison da Silva Sabino"
            />

            <WinnerCard
              avatarImg={winner4}
              digimoedas="3960"
              prizeImg={bag}
              prizeName="Mochila 2 Fjallraven"
              winnerName="Fabio Junior Fernandes"
            />

            <WinnerCard
              avatarImg={winner5}
              digimoedas="3030"
              prizeImg={alexaShow}
              prizeName="Alexa Eco Show"
              reverse
              winnerName="Odair José da Silva Marques"
            />

            <WinnerCard
              avatarImg={winner6}
              digimoedas="3010"
              prizeImg={bag2}
              prizeName="Mochila 1 Reserva"
              reverse
              winnerName="Yan Savio Alves da Costa"
            />

            <WinnerCard
              avatarImg={winner7}
              digimoedas="2740"
              prizeImg={jbl}
              prizeName="JBL Mini"
              reverse
              winnerName="Leandro Lomeu de Avelar"
            />

            <WinnerCard
              avatarImg={winner8}
              digimoedas="2840"
              prizeImg={bag2}
              prizeName="Mochila 2 Reserva"
              reverse
              winnerName="Lucas Peixoto Ortiz"
            />
          </S.WinnersContainer>
        </S.BannerBg>
      </S.BannerContainer>
    </S.Container>
  </S.Template>
}
